import React from 'react';
import { createRoot } from 'react-dom/client';

window.__VITE_COMPONENTS__ = {};

/**
 * Ruby creates a div and put data as JSON to the props attribute
 * this method renders rect component ro the div
 * it fires immediately when js is ready to execute
 * @param rootId - element id
 * @param Component - react component
 */
export const createContainer = <TProps,>(rootId: string, Component: React.FC<TProps>) => {
  const nodes = document.querySelectorAll(`#${rootId}`);

  window.__VITE_COMPONENTS__[rootId] = Component as React.FC;
  if (!nodes.length) {
    return;
  }

  nodes.forEach((node) => {
    const props = JSON.parse(node.getAttribute('props') as string);
    createRoot(node).render(<Component {...props} />);
  });
};

/**
 * Stores the React component to use it later in JS (JQuery)
 * @param rootId - element id
 * @param Component - react component
 */
export const storeComponent = <TProps,>(rootId: string, Component?: React.FC<TProps>) => {
  window.__VITE_COMPONENTS__[rootId] = Component as React.FC;
};

const getElementsAndId = (rootId: string | HTMLElement): [string, HTMLElement[] | null] => {
  if (typeof rootId === 'string') {
    const nodes: HTMLElement[] = [];
    document.querySelectorAll<HTMLElement>(`#${rootId}`).forEach((node) => {
      nodes.push(node);
    });
    return [rootId, nodes];
  }
  const { id } = rootId;
  return [id, [rootId]];
};

/**
 * Renders react component which was stored by *storeComponent*
 * @param idOrElement - element id or element itself
 * @param renderNode - custom node to render
 */
export const renderContainer = (idOrElement: string | HTMLElement, renderNode?: HTMLElement) => {
  const [rootId, nodes] = getElementsAndId(idOrElement);
  if (!nodes?.length) {
    return;
  }

  if (!window.__VITE_COMPONENTS__[rootId]) {
    throw new Error(`${rootId} not found`);
  }

  const Component = window.__VITE_COMPONENTS__[rootId];

  nodes.forEach((node) => {
    const props = JSON.parse(node.getAttribute('props') as string);
    if (renderNode) {
      createRoot(renderNode).render(<Component {...props} />);
    } else {
      createRoot(node).render(<Component {...props} />);
    }
  });
};

window.renderContainer = renderContainer;
